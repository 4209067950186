// extracted by mini-css-extract-plugin
export var address = "LocationTile__address__Utj30";
export var agreementCheck = "LocationTile__agreementCheck__pZ9mO";
export var anchor = "LocationTile__anchor__JVMT6";
export var badge = "LocationTile__badge__uF2HK";
export var column = "LocationTile__column__X6X9W";
export var details = "LocationTile__details__sXkB0";
export var detailsContainer = "LocationTile__detailsContainer__uy3D1";
export var image = "LocationTile__image__ylKp6";
export var listLocation = "LocationTile__listLocation__Q6ymU";
export var listLocationSummary = "LocationTile__listLocationSummary__bMyaE";
export var location = "LocationTile__location__tZKRS";
export var locationImage = "LocationTile__locationImage__LKWul";
export var logo = "LocationTile__logo__gcCnr";
export var name = "LocationTile__name__NrDKr";
export var photo = "LocationTile__photo__NP1l3";
export var productsInfo = "LocationTile__productsInfo__ilGp5";
export var provider = "LocationTile__provider__BSecG";
export var row = "LocationTile__row__PHfjW";
export var sponsored = "LocationTile__sponsored__NFHRk";
export var sponsoredLabel = "LocationTile__sponsoredLabel__Gwedo";